<template>
  <mynav navIndex="0"></mynav>
  <div class='main'>
    <div class="contain" v-for="(itemDatas, futuresName) in futuresDatas" :key="futuresName">
      <div class="futures-class">
        {{futuresName}}
      </div>
      <div class="link-light"></div>
      <div class="futures-contain">
        <div class="futures" v-for="(item, index) in itemDatas" :key="index">        
          <a :href="item.url" rel="noopener noreferrer" class="title-contain">
            <div class="title">
              {{item.type}}
            </div>
            <div class="title-link">
              走势图>
            </div>
          </a>
          <a :href="item.url" rel="noopener noreferrer" class="data-contain">
            <div class="data font-bold">最新: {{ item.end_price }}</div>
            <div class="data" v-html="item.change"></div>
            <div class="data">今开: {{item.start_price}}</div>
            <div class="data">最高: {{item.max_price}}</div>
            <div class="data">最低: {{item.min_price}}</div>
            <div class="data">昨收: {{item.y_end_price}}</div>
          </a>
        </div>
      </div>
    </div>
    <br><br><br>
  </div>
</template>

<script>
import axios from "axios";
import datos from "../apps.js"
export default {
  name: 'IndexFutures',
  data() {
    return {
      futuresDatas: {},
      types: {
        0: {'title': '黄金', 'url': '/price/gd/day.html'},
        1: {'title': '白银', 'url': '/price/si/day.html'},
        2: {'title': '铂金', 'url': '/price/pl/day.html'},
        3: {'title': '钯金', 'url': '/price/pa/day.html'},
        4: {'title': '黄金', 'url': '/price/nygd/day.html'},
        5: {'title': '白银', 'url': '/price/nysi/day.html'},
        6: {'title': '铂金', 'url': '/price/nypl/day.html'},
        7: {'title': '钯金', 'url': '/price/nypa/day.html'},
        40: {'title': '美元', 'url': '/price/rmbdol/day.html'},
        41: {'title': '欧元', 'url': '/price/rmbeu/day.html'},
      },
      num: 0
    }
  },
  mounted(){
    this.getFuturesData()
  },
  beforeUnmount(){
    this.futuresDatas = {}
  },
  methods:{
    getFuturesData() {
      axios
      .get("/" + datos()[this.num].desc + "/v1/futures/index")
      .then((data) => {
        if (data.data.code == 200) {
          var dataType
          var types = ['人民币贵金属', '纽约金', '人民币汇率']
          var tmpDatas = {}
          for (let index = 0; index < data.data.data.length; index++) {
            dataType = data.data.data[index].type
            data.data.data[index].change = 100 * (
              data.data.data[index].end_price - data.data.data[index].y_end_price) / data.data.data[index].y_end_price
            data.data.data[index].change = data.data.data[index].change.toFixed(2)
            if (data.data.data[index].change < 0) {
              data.data.data[index].change = '<strong style="color:#26A69A">日幅:'+ data.data.data[index].change +'%</strong>'
            } else if (data.data.data[index].change == 0) {
              data.data.data[index].change = '<strong>日幅: +'+ data.data.data[index].change +'%</strong>'
            }else{
              data.data.data[index].change = '<strong style="color:#EF5350">日幅: +'+ data.data.data[index].change +'%</strong>'
            }
            var ftype = ""
            if (dataType < 4){
              ftype = "人民币贵金属"
            }else if (dataType >= 4 && dataType < 8) {
              ftype = "纽约金"
            }else{
              ftype = "人民币汇率"
            }
            data.data.data[index].type = this.types[dataType]['title']
            data.data.data[index].url = this.types[dataType]['url']

            if (tmpDatas[ftype]) {
              tmpDatas[ftype].push(data.data.data[index])
            }else{
              tmpDatas[ftype] = [data.data.data[index]]
            }
            for (let index = 0; index < types.length; index++) {
              this.futuresDatas[types[index]] = tmpDatas[types[index]]              
            }

          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }
}
</script>

<style scoped>
@import '../css/main.css';
a{
  cursor: pointer;
  text-decoration: none;
}
.contain{
  width: 80%;
  margin: auto;
  margin-top: 1rem;
}
.futures-class {
  margin: auto;
  text-align: left;
  font-size: 1.4rem;
  color: #716f4c;
  font-weight: 500;
}
.link-light{
  height: 1px;
  margin: auto;
  border-top: solid #e6e6e6 1px
}
.futures-contain {
  display: flex;
  flex-wrap: wrap;
}
.futures { 
  width: 47%;
  text-align: left;
  margin: auto;
  margin-top: 0.4rem;
  padding: 1%;
  background-color: rgb(247, 245, 241);
}

.title-contain {
  color: #666;
  width: 100%;
  position: relative;
}

.title {
  display: inline-block;
  height: 1.8rem;
  text-align: left;
  width: 45%;
  font-size: 1.3rem;
  font-weight: 500;
  /* background-color: blueviolet; */
}
.title-link {
  display: inline-block;
  height: 1.3rem;
  text-align: right;
  width: 40%;
  font-size: 1rem;
  /* background-color: aqua; */
}

.data-contain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 0.2rem;
}
.data{
  width: 31%;
  margin: auto;
  margin-top: 0.2rem;
  color: #575757
}



@media screen and (max-width: 540px){
/* 手机端CSS代码 */
  .contain{
    width: 100%;
  }
  .futures{
    width: 100%;
  }
  .data{
    width: 45%;
  }
}
@media screen and (min-width: 541px) and (max-width: 1028px){
  .contain{
    width: 100%;
  }
}
</style>
